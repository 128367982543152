import React from "react";

function CompanyInformations()
{

    return(

        <div id="CompanyInformations" className="container">
        <h1>Company Information</h1>
        <div className="company-info">
          <h2>Company Name</h2>
          <p>Peak Technologies</p>
          <h2>Location</h2>
          <p>123 Main Street, City, Country</p>
          <h2>Description</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non mi sit amet quam mollis pretium. Proin auctor felis sed mauris dapibus, id malesuada libero convallis.</p>
          <h2>Contact Information</h2>
          <p>Email: info@peaktech.com<br/>Phone: +123456789</p>
        </div>
      </div>


    );

}

export default CompanyInformations