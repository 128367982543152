import React from "react";

function Notifications() {

  return (

    <div id="Notifications" className="container">
      <h1 className="text-center bg-dark text-light">Notification Page</h1>
    </div>


  );

}

export default Notifications