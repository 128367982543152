import React,{useState} from "react";

const Reports = () => {
    const [selectedDocument, setSelectedDocument] = useState(null);
    const documents = [
      { id: 1, name: 'Document 1', content: 'Content of Document 1' },
      { id: 2, name: 'Document 2', content: 'Content of Document 2' },
      { id: 3, name: 'Document 3', content: 'Content of Document 3' },
    ];
  
    const handleDocumentSelect = (document) => {
      setSelectedDocument(document);
    };
  
    return (
      <div id="Reports" className="container">
        <h1>Document Viewer</h1>
        <div className="row">
          <div className="col-md-4">
            <h2>Document List</h2>
            <ul>
              {documents.map((document) => (
                <li key={document.id}>
                  <button onClick={() => handleDocumentSelect(document)}>
                    {document.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-8">
            <h2>Selected Document</h2>
            {selectedDocument ? (
              <div>
                <h3>{selectedDocument.name}</h3>
                <p>{selectedDocument.content}</p>
              </div>
            ) : (
              <p>Please select a document to view</p>
            )}
          </div>
        </div>
      </div>
    );
  }
  
  export default Reports;