import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

function MainHeader({ setSelectedTab }) {

  const data = 0;

  const { setToken } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    setToken();
    navigate("/", { replace: true });
  };

  function onLogout(event) {
    handleLogout();
  }

  const handleMenuClick = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  return (
    <header class="bg-dark text-white py-3">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 w-25 text-center">
            <img src="img/Peak_Logo_Kobalt.png" className=" w-50 h-50" alt="" />
          </div>
          <div class="col-md-9 w-75">
            <nav class="text-center">
              <ul class="list-inline list-unstyled rounded-list">
                <li onClick={() => handleMenuClick(0)} className="list-inline-item rounded p-2">
                  Anasayfa
                </li>
                <li onClick={() => handleMenuClick(1)} className="list-inline-item rounded p-2">
                  Teknokent Raporları
                </li>
                <li onClick={() => handleMenuClick(2)} className="list-inline-item rounded p-2">
                  Bildirimler
                </li>
                <li onClick={() => onLogout()} className="list-inline-item rounded p-2">
                  Logout
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>


  )
}

export default MainHeader
