import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

function LoginPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState(false);

  const { setToken } = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    setToken("This is a login token.");
    navigate("/", { replace: true });
  };




  function handleSubmit(event) {
    event.preventDefault();
    axios.post('https://portal.peaksoftcon.com:8081/login', { email, password })
      .then(res => {
        if (res.data === "No Record") {
          setLoginError(true); // Set login error state to true
        }
        else {
          handleLogin();
        }
        console.log(res.data);
      })
      .catch(err => console.log(err));

  }


  return (
    <div id="LoginPage" className='d-flex vh-100 w-100 justify-content-center align-items-center bg-dark '>
      <div className='position-sticky w-50 d-flex flex-column align-items-center'>
        <div className='mx-auto d-block mt-3 w-50 m-3'>
          <img src="img/Peak_Logo_Kobalt.png" className="img-fluid mx-auto d-block mt-3 w-75" alt="" />
        </div>
        <div className='p-3 bg-secondary mt-3 w-75 rounded'>
          <form onSubmit={handleSubmit} className='w-100'>
            <div className='mb-3'>
              <label className="badge bg-primary text-ligt m-1" htmlFor="email">Email</label>
              <input type="email" placeholder='Enter Email' className='form-control font-monospace' onChange={e => setEmail(e.target.value)} />
            </div>

            <div className='mb-3'>
              <label className="badge bg-primary text-ligt m-1" htmlFor="password">Password</label>
              <input type="password" placeholder='Enter password' className='form-control font-monospace' onChange={e => setPassword(e.target.value)} />
            </div>
            <button className='btn btn-primary m-1 w-100'>Login</button>
            {loginError && <p id="alert" class="text-danger text-center font-weight-800">Please Check Your Username And Password!</p>}
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
