import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

function MainFooter() {




    return (
        <footer className="bg-dark text-center text-muted py-2 fixed-bottom">
            <div className="container">
                <p className='text-light'>&copy; 2024 Peak Software & Engineering. All rights reserved.</p>
            </div>
        </footer>


    )
}

export default MainFooter
