import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import CompanyInformations from './CompanyInformations'
import Reports from './Reports'
import Notifications from './Notifications'


function MainBody({ data }) {


    return (
        <div id="MainBody" className="container-fluid bg-info py-4 vh-100">
            {data === 0 && <CompanyInformations />}
            {data === 1 && <Reports />}
            {data === 2 && <Notifications />}
        </div>
    )
}

export default MainBody
