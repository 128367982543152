import React, { useState } from 'react'
import MainHeader from './MainHeader'
import MainBody from './MainBody';
import MainFooter from './MainFooter'



function MainPage() {
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <div>
            <MainHeader setSelectedTab={setSelectedTab} />
            <MainBody data={selectedTab} />
            <MainFooter />
        </div>
    );
};

export default MainPage;